import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    Row
} from 'reactstrap';

import { ArrowClockwise } from 'react-bootstrap-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';

import OrdersList from './OrdersList';
// import OrderAddModal from './modals/OrderAddModal';
import { OrderProps, OrderStatus } from '../../../types';
import { useDataContext } from '../../../components/contexts/DataProvider';
import { formatDate } from '../../../helpers/AssetHelpers';
import OrderStatusesMenu from './OrderStatusesMenu';

import './OrdersListView.css';

/**
 * 
 * @returns 
 */
const OrdersListView: React.FC = () => {
    /**
     * 
     */
    const { t } = useTranslation();

    /**
     * 
     */
    const { orders, fetchOrders } = useDataContext();

    /**
     * 
     */
    const [searchedText, setSearchText] = useState<string>('');

    /**
     * 
     */
    const [filterStatus, setFilterStatus] = useState<OrderStatus|null>(null);

    /**
     * 
     */
    const [filteredOrders, setFilteredOrders] = useState<OrderProps[]>([]);

    /**
     * 
     */
    const [fromSearch, setFromSearch] = useState<boolean>(false);

    /**
     * 
     * @param order 
     * @returns 
     */
    // const checkCategory = (order: OrderProps) => {
    //     return filterCategory !== null && order.category.id === filterCategory.id;
    // };

    /**
     * 
     * @param order 
     * @returns 
     */
    const checkStatus = useCallback((order: OrderProps) => {
        return filterStatus !== null && order.status.id === filterStatus.id;
    }, [filterStatus]);

    /**
     * 
     * @param order 
     * @returns 
     */
    const checkSearchedText = useCallback((order: OrderProps) => {

        const user = order.customer.user;

        return (order.string_id.toLowerCase().indexOf(searchedText) > -1 ||
                user.first_name.toLowerCase().indexOf(searchedText) > -1 ||
                user.last_name.toLowerCase().indexOf(searchedText) > -1 ||
                `${user.first_name} ${user.last_name}`.toLowerCase().indexOf(searchedText) > -1 ||
                order.address.toLowerCase().indexOf(searchedText) > -1 ||
                order.montant.toString().indexOf(searchedText) > -1 ||
                formatDate(new Date(order.created_at), 'long', '-', false).toString().indexOf(searchedText) > -1);
    }, [searchedText]);

    /**
     * 
     */
    useEffect(() => {
        let f_orders = filterStatus !== null ? orders.filter(checkStatus) : orders;
        setFromSearch(filterStatus !== null);

        // if(filterStatus !== -1){
        //     f_orders = f_orders.filter(checkStatus);
        //     setFromSearch(true);
        // }
        if(searchedText.length > 0){
            f_orders = f_orders.filter(checkSearchedText);
            setFromSearch(true);
        }
        setFilteredOrders(f_orders);
        // setFilteredOrders(!searchedText.length ? orders : orders.filter(checkSearchedText));
	}, [orders, searchedText, filterStatus, checkStatus, checkSearchedText]);

    /**
     * 
     * @param text 
     */
    const handleSearchInputChange = (text: string) => {
        setSearchText(text);
    };

    /**
     * 
     */
    const [showOrderAddModal, setShowOrderAddModal] = useState<boolean>(false);

    /**
     * 
     * @returns 
     */
    const toggleOrderAddModal = () => setShowOrderAddModal(!showOrderAddModal);

    /**
     * 
     */
    const searchbar = useRef<HTMLDivElement|null>(null);

    /**
     * 
     */
    return (
        <div className='orders-list-view'>
            <Card className='border-0'>
                <CardHeader className='py-4'>
                    <Row className='align-items-center g-4'>
                        <Col xs='sm'>
                            <CardTitle
                                tag='h3'
                                className='title-color h6 m-0'
                            >
                                <span>{t('Liste des commandes')}</span>
                            </CardTitle>
                        </Col>
                        <Col xs='sm-auto'>
                            {/* begin::Buttons */}
                            <div className='btns-wrapper d-flex gap-2'>
                                {/* <Button
                                    tag='button'
                                    type='button'
                                    color='success'
                                    className='btn-add btn-add-order fs-8 rounded-1'
                                    onClick={() => setShowOrderAddModal(true)}
                                >
                                    <span className='me-2'>
                                        <FontAwesomeIcon icon={faPlus} size='sm' />
                                    </span>
                                    <span>{t('Nouveau produit')}</span>
                                </Button> */}
                                <Button
                                    tag='button'
                                    type='button'
                                    color="info2"
                                    size='md'
                                    className="d-flex flex-center gap-2 rounded-1"
                                    onClick={() => fetchOrders()}
                                >
                                    <ArrowClockwise />
                                </Button>
                            </div>
                            {/* end::Buttons */}
                        </Col>
                    </Row>
                </CardHeader>
                <CardHeader className='py-5'>
                    <div className="toolbar orders-list-toolbar">
                        <div className="toolbar-content">
                            <Form>
                                <Row className='align-items-center g-3'>
                                    <Col xl={6}>
                                        {/* begin::Searchbar */}
                                        <div
                                            ref={searchbar}
                                            className='searchbar'
                                        >
                                            <InputGroup className='input-group-searbar h-40px'>
                                                <InputGroupText
                                                    tag='div'
                                                    className='icon-search-wrapper py-0 pe-1 h-100'
                                                >
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                </InputGroupText>
                                                <Input
                                                    type='text'
                                                    placeholder={t('Rechercher')}
                                                    className='search-input searchbar-search-input form-control h-100'
                                                    value={searchedText}
                                                    onInput={(e: React.FormEvent<HTMLInputElement>) => handleSearchInputChange(e.currentTarget.value)}
                                                    onFocus={() => {
                                                        searchbar.current?.classList.add('focus');
                                                    }}
                                                    onBlur={() => {
                                                        searchbar.current?.classList.remove('focus');
                                                    }}
                                                />
                                                <InputGroupText
                                                    tag='div'
                                                    className='icon-clear-wrapper py-0 pe-1 h-100'
                                                >
                                                    <Button
                                                        tag='button'
                                                        type='button'
                                                        size='sm'
                                                        className={searchedText.length ? 'd-inline-block' : 'd-none'}
                                                        onClick={() => setSearchText('')}
                                                    >
                                                        <FontAwesomeIcon icon={faXmark} />
                                                    </Button>
                                                </InputGroupText>
                                            </InputGroup>
                                        </div>
                                        {/* end::Searchbar */}
                                    </Col>
                                    <Col xl={6}>
                                        {/* begin::Filters */}
                                        <Row className='align-items-center g-3'>
                                            {/* <Col sm={6} md={4} xl={6}>
                                                <OrderCategoriesMenu
                                                    selectedCategory={filterCategory}
                                                    setSelectedCategory={setFilterCategory}
                                                />
                                            </Col> */}
                                            <Col sm={6} md={4} xl={6}>
                                                <OrderStatusesMenu
                                                    selectedStatus={filterStatus}
                                                    setSelectedStatus={setFilterStatus}
                                                />
                                            </Col>
                                        </Row>
                                        {/* end::Filters */}
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </CardHeader>
                <CardBody className='px-0 pt-0'>
                    <OrdersList
                        orders={filteredOrders}
                        fromSearch={fromSearch}
                    />
                </CardBody>
            </Card>
            {/* begin::Modals */}
            {/* <OrderAddModal
                show={showOrderAddModal}
                setShow={setShowOrderAddModal}
                toggle={toggleOrderAddModal}
            /> */}
            {/* end::Modals */}
        </div>
    );
};

export default OrdersListView;
