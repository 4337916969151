import React from "react";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Row
} from "reactstrap";

import { ArrowLeft } from "react-bootstrap-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

import { OrderProps } from "../../types";
import { formatDate, formatNumber, formatPhoneNumber, toAbsolutePublicUrl } from "../../helpers/AssetHelpers";
import useGoTo from "../../components/hooks/useGoTo";
import OrderBoxDetails from "./components/OrderBoxDetails";
import OrderProductDetails from "./components/OrderProductDetails";

import './OrderInvoicePageContent.css';

/**
 * 
 * @param order 
 * @returns 
 */
const OrderInvoicePageContent: React.FC<OrderProps> = (order: OrderProps) => {
    /**
     * 
     */
    const { t } = useTranslation();

    /**
     * 
     */
    const goTo = useGoTo();

    /**
     * 
     */
    return (
        <div
            id="orderInvoicePageContent"
            className="page-content position-relative"
        >
            <Container
                fluid={true}
                className="page-content-container p-5 p-sm-6 p-md-8 p-xl-10"
            >
                <Row className="gy-4">
                    {/*  */}
                    <Col xs={12} className="no-printable">
                        <Card className='border-0 rounded-1'>
                            <CardBody>
                                <div className='d-flex flex-row flex-wrap flex-stack'>
                                    <div className='d-flex align-items-center'>
                                        <Button
                                            tag='button'
                                            type='button'
                                            title={t('Retour')}
                                            color='light'
                                            className="d-flex flex-center gap-1 h-40px"
                                            onClick={() => goTo(`/commandes/${order.string_id}`)}
                                        >
                                            <ArrowLeft />
                                        </Button>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <Button
                                            tag='button'
                                            type='button'
                                            color='success'
                                            className="rounded-1 "
                                            onClick={() => {
                                                window.print();
                                            }}
                                        >
                                            <span className="icon-wrapper me-2">
                                                <FontAwesomeIcon icon={faPrint} />
                                            </span>
                                            <span>{t('Imprimer')}</span>
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {/*  */}
                    {/*  */}
                    <Col xs={12}>
                        <Card className="invoice order-invoice">
                            <CardHeader className="p-4 pb-5 border-bottom-dashed">
                                <Row className="gy-4">
                                    {/*  */}
                                    <Col xs={12}>
                                        <div className="d-flex flex-stack pt-3 pb-3">
                                            <div className="title-wrapper">
                                                <CardTitle
                                                    tag='h3'
                                                    className="custom mb-0"
                                                >
                                                    <span>{t('Facture')}</span>
                                                </CardTitle>
                                            </div>
                                            <div className='invoice-logo-wrapper d-flex flex-center'>
                                                <img
                                                    src={toAbsolutePublicUrl('/assets/media/images/logos/profood-new.png')}
                                                    className="img-fluid w-130px"
                                                    alt='Logo Profood'
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    {/*  */}
                                    {/*  */}
                                    <Col xs={12}>
                                        <div className="d-flex flex-stack">
                                            {/*  */}
                                            <div className="d-flex flex-column">
                                                <div className="d-flex align-items-center">
                                                    <CardSubtitle
                                                        tag='h6'
                                                        className='custom text-uppercase fw-semibold text-muted mb-3'
                                                    >
                                                        <span>{t('Client(e)')}</span>
                                                    </CardSubtitle>
                                                </div>
                                                <div className='mb-2'>
                                                    <span className="fw-medium">{`${order.customer.user.first_name} ${order.customer.user.last_name}`}</span>
                                                </div>
                                                <div className='d-flex flex-column gap-1'>
                                                    <div className='d-flex align-items-center gap-1'>
                                                        <span className="text-muted fw-normal">{t('Numéro de téléphone')}:</span>
                                                        <span>{formatPhoneNumber(order.customer.user.phone_number)}</span>
                                                    </div>
                                                    <div className='d-flex align-items-center gap-1'>
                                                        <span className="text-muted fw-normal">{t('Adresse e-mail')}:</span>
                                                        <span>{order.customer.user.email}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*  */}
                                            {/*  */}
                                            <div className="d-flex flex-column align-items-end gap-1">
                                                <div className="d-flex align-items-center gap-1">
                                                    <span className="text-muted fw-normal">{t('Adresse e-mail')}:</span>
                                                    <span>contact@profood.sn</span>
                                                </div>
                                                <div className="d-flex align-items-center gap-1">
                                                    <span className="text-muted fw-normal">{t('Site web')}:</span>
                                                    <a
                                                        href="https://www.profood.sn/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        id="website"
                                                        className="link-info2 link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
                                                    >
                                                        <span>www.profood.sn</span>
                                                    </a>
                                                </div>
                                                <div className="d-flex align-items-center gap-1">
                                                    <span className="text-muted fw-normal">{t('Numéro de téléphone')}:</span>
                                                    <span>+221 77 856 89 89</span>
                                                </div>
                                                <div className="d-flex align-items-center gap-1">
                                                    <span className="text-muted fw-normal">{t('Adresse')}:</span>
                                                    <span>Lot 44, Route des Mamelles, Dakar - Sénégal</span>
                                                </div>
                                            </div>
                                            {/*  */}
                                        </div>
                                    </Col>
                                    {/*  */}
                                </Row>
                            </CardHeader>
                            <CardHeader className='p-4 pb-3 border-bottom-dashed'>
                                <div className='d-flex flex-row flex-stack'>
                                    <div className="d-flex align-items-center">
                                        <CardSubtitle
                                            tag='h6'
                                            className='custom text-uppercase fw-medium mb-0'
                                        >
                                            <span className="text-muted me-1">{t('Commande')} n°</span>
                                            <span>{order.string_id}</span>
                                        </CardSubtitle>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <CardSubtitle className="fs-8 fw-medium">
                                            <span>{formatDate(new Date(order.created_at), 'full', '-', true, 'short')}</span>
                                        </CardSubtitle>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row className="gy-4">
                                    {/*  */}
                                    <Col xs={12}>
                                        {/* <OrderBoxDetails {...order} /> */}
                                    { order.cart.boxes_data.length > 0 && <OrderBoxDetails {...order} /> }
                                    </Col>
                                    {/*  */}
                                    {/*  */}
                                    <Col xs={12}>
                                    { order.cart.slices_data.length > 0 && <OrderProductDetails {...order} /> }
                                    </Col>
                                    {/*  */}
                                </Row>
                            </CardBody>
                            <CardFooter className="p-4 pb-6 border-0">
                                <div className='d-flex flex-row flex-stack'>
                                    <div className='d-flex flex-column gap-1'>
                                        <div className="d-flex align-items-center">
                                            <CardTitle
                                                tag='h6'
                                                className='fs-8 custom font-sm text-uppercase fw-semibold text-muted mb-3'
                                            >
                                                <span>{t('Détails de paiement')}</span>
                                            </CardTitle>
                                        </div>
                                        <div className='d-flex flex-stack gap-5'>
                                            <span className='text-muted fw-normal'>{t('Mode de paiement')}:</span>
                                            <span>{t(order.payment_method)}</span>
                                        </div>

                                        <div className='d-flex flex-stack gap-5'>
                                            <span className='text-muted fw-normal'>{t('Montant Total')}:</span>
                                            <span className='order-amount-label fw-medium fs-7'>
                                                <span>{formatNumber(order.montant)}</span>
                                                <small className='ms-1'>Fcfa</small>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    {/*  */}
                    {/*  */}
                    <Col xs={12} className="no-printable">
                        <Card className='border-0 rounded-1'>
                            <CardBody>
                                <div className='d-flex flex-row flex-wrap align-items-center justify-content-end'>
                                    <div className='d-flex align-items-center'>
                                        <Button
                                            tag='button'
                                            type='button'
                                            color='success'
                                            className="rounded-1 "
                                            onClick={() => {
                                                window.print();
                                            }}
                                        >
                                            <span className="icon-wrapper me-2">
                                                <FontAwesomeIcon icon={faPrint} />
                                            </span>
                                            <span>{t('Imprimer')}</span>
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {/*  */}
                </Row>
            </Container>
        </div>
    );
};

export default OrderInvoicePageContent;
