import { createContext } from "react";

import {
    BoxTypeProps,
    CategoryProps,
    CustomDateRange,
    CustomerProps,
    OrderPaymentStatus,
    OrderProps,
    OrdersStatisticsDetails,
    OrderStatus,
    SliceProps,
    UserProps,
    UserRoleProps
} from '../../types';

/**
 * 
 */
export interface DataContextType {
    boxTypes: BoxTypeProps[];
    categories: CategoryProps[];
    customers: CustomerProps[];
    orders: OrderProps[];
    orderPaymentStatuses: OrderPaymentStatus[];
    ordersStatisticsDetails?: OrdersStatisticsDetails;
    orderStatuses: OrderStatus[];
    slices: SliceProps[];
    userRoles: UserRoleProps[];
    users: UserProps[];
    statisticsCustomDateRange: CustomDateRange;
    statisticsStartDate: Date|null;
    statisticsEndDate: Date|null;
    fetchBoxTypes: (showSpinner?: boolean, spinnerTime?: number) => void;
    fetchCategories: (showSpinner?: boolean, spinnerTime?: number) => void;
    fetchCustomers: (showSpinner?: boolean, spinnerTime?: number) => void;
    fetchData: () => void;
    fetchOrders: (showSpinner?: boolean, spinnerTime?: number) => void;
    fetchOrderPaymentStatuses: (showSpinner?: boolean, spinnerTime?: number) => void;
    fetchOrdersStatisticsDetails: (startDate: Date|null, endDate: Date|null, showSpinner?: boolean, spinnerTime?: number) => void;
    fetchOrderStatuses: (showSpinner?: boolean, spinnerTime?: number) => void;
    fetchSlices: (showSpinner?: boolean, spinnerTime?: number) => void;
    fetchUserRoles: (showSpinner?: boolean, spinnerTime?: number) => void;
    fetchUsers: (showSpinner?: boolean, spinnerTime?: number) => void;
    setBoxTypes: (boxTypes: BoxTypeProps[]) => void;
    setCategories: (categories: CategoryProps[]) => void;
    setCustomers: (customers: CustomerProps[]) => void;
    setOrders: (orders: OrderProps[]) => void;
    setOrderPaymentStatuses: (statuses: OrderPaymentStatus[]) => void;
    setOrdersStatisticsDetails: (statisticsDetails?: OrdersStatisticsDetails) => void;
    setOrderStatuses: (statuses: OrderStatus[]) => void;
    setSlices: (slices: SliceProps[]) => void;
    setUserRoles: (roles: UserRoleProps[]) => void;
    setUsers: (users: UserProps[]) => void;
    setStatisticsCustomDateRange: (range: CustomDateRange) => void;
    setStatisticsStartDate: (date: Date|null) => void;
    setStatisticsEndDate: (date: Date|null) => void;
}

/**
 * Create the data context
*/
const DataContext = createContext<DataContextType>({
    boxTypes: [],
    categories: [],
    customers: [],
    orders: [],
    orderPaymentStatuses: [],
    ordersStatisticsDetails: undefined,
    orderStatuses: [],
    slices: [],
    userRoles: [],
    users: [],
    statisticsCustomDateRange: 'all',
    statisticsStartDate: null,
    statisticsEndDate: null,
    fetchBoxTypes:() => {/* */},
    fetchCategories:() => {/* */},
    fetchCustomers: () => {/* */},
    fetchData:() => {/* */},
    fetchOrders: () => {/* */},
    fetchOrderPaymentStatuses: () => {/* */},
    fetchOrdersStatisticsDetails: () => {/* */},
    fetchOrderStatuses: () => {/* */},
    fetchSlices:() => {/* */},
    fetchUsers: () => {/* */},
    fetchUserRoles: () => {/* */},
    setBoxTypes: () => {/* */},
    setCategories: () => {/* */},
    setCustomers: () => {/* */},
    setOrders: () => {/* */},
    setOrderPaymentStatuses: () => {/* */},
    setOrdersStatisticsDetails : () => {/* */},
    setOrderStatuses: () => {/* */},
    setSlices: () => {/* */},
    setUsers: () => {/* */},
    setUserRoles: () => {/* */},
    setStatisticsCustomDateRange: () => {/* */},
    setStatisticsStartDate: () => {/* */},
    setStatisticsEndDate: () => {/* */}
});

export default DataContext;
