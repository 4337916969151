import React, { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import api from "../../api/api";
import DataContext, { DataContextType } from "./DataContext";
import {
    BoxTypeProps,
    CategoryProps,
    CustomDateRange,
    CustomerProps,
    OrderPaymentStatus,
    OrderProps,
    OrderStatus,
    OrdersStatisticsDetails,
    SliceProps,
    UserProps,
    UserRoleProps
} from "../../types";
import { useLoadingSpinnerContext } from "./LoadingSpinnerProvider";
import useToast from "../hooks/useToast";

/**
 * 
 */
interface Props {
    children: React.ReactNode;
}

/**
 * 
 * @returns 
 */
export const useDataContext = () => useContext(DataContext);

/**
 * Create the provider component
 *
 * @param param0 
 * @returns 
 */
const DataProvider = ({ children }: Props) => {
    /**
     * 
     */
    const [boxTypes, setBoxTypes] = useState<BoxTypeProps[]>([]);
    const [categories, setCategories] = useState<CategoryProps[]>([]);
    const [customers, setCustomers] = useState<CustomerProps[]>([]);
    const [orders, setOrders] = useState<OrderProps[]>([]);
    const [orderPaymentStatuses, setOrderPaymentStatuses] = useState<OrderPaymentStatus[]>([]);
    const [ordersStatisticsDetails, setOrdersStatisticsDetails] = useState<OrdersStatisticsDetails|undefined>(undefined);
    const [orderStatuses, setOrderStatuses] = useState<OrderStatus[]>([]);
    const [slices, setSlices] = useState<SliceProps[]>([]);
    const [users, setUsers] = useState<UserProps[]>([]);
    const [userRoles, setUserRoles] = useState<UserRoleProps[]>([]);
    const [statisticsCustomDateRange, setStatisticsCustomDateRange] = useState<CustomDateRange>('all');
    const [statisticsStartDate, setStatisticsStartDate] = useState<Date|null>(new Date('2024-01-01'));
    const [statisticsEndDate, setStatisticsEndDate] = useState<Date|null>(new Date());

    /**
     * 
     */
    const { t } = useTranslation();

    /**
     * 
     */
    const { setShowSpinner } = useLoadingSpinnerContext();

    /**
     * 
     */
    const showToast = useToast();

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchBoxTypes = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        const token = localStorage.getItem('token');

        if(token !== null){
            if(showSpinner){
                setShowSpinner(true);
            }
            api.get('/get-box-types', {
                headers:{
                  Authorization:`Bearer ${token}`,
                }
            }).then((res) => {
                setBoxTypes(res.data);
                setTimeout(() => {
                    if(showSpinner){
                        setShowSpinner(false);
                    }
                }, spinnerTime);
            }).catch((error) => {
                setShowSpinner(false);
                showToast(t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur"), 'error');
                console.log(error);
            });
        }
    }, [setShowSpinner, showToast]);

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchCategories = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        const token = localStorage.getItem('token');

        if(token !== null){
            if(showSpinner){
                setShowSpinner(true);
            }
            api.get('/get-categories-with-slices-count', {
                headers:{
                  Authorization:`Bearer ${token}`,
                }
            }).then((res) => {
                setCategories(res.data);
                setTimeout(() => {
                    if(showSpinner){
                        setShowSpinner(false);
                    }
                }, spinnerTime);
            }).catch((error) => {
                setShowSpinner(false);
                showToast(t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur"), 'error');
                console.log(error);
            });
        }
    }, [setShowSpinner, showToast]);

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchCustomers = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        const token = localStorage.getItem('token');

        if(token !== null){
            if(showSpinner){
                setShowSpinner(true);
            }
            api.get('/get-customers-with-linked-users', {
                headers:{
                  Authorization:`Bearer ${token}`,
                }
            }).then((res) => {
                setCustomers(res.data);
                setTimeout(() => {
                    if(showSpinner){
                        setShowSpinner(false);
                    }
                }, spinnerTime);
            }).catch((error) => {
                setShowSpinner(false);
                showToast(t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur"), 'error');
                console.log(error);
            });
        }
    }, [setShowSpinner, showToast]);

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchOrders = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        const token = localStorage.getItem('token');

        if(token !== null){
            if(showSpinner){
                setShowSpinner(true);
            }
            api.get('/get-orders', {
                headers:{
                  Authorization:`Bearer ${token}`,
                }
            }).then((res) => {
                setOrders(res.data);
                setTimeout(() => {
                    if(showSpinner){
                        setShowSpinner(false);
                    }
                }, spinnerTime);
            }).catch((error) => {
                setShowSpinner(false);
                showToast(t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur"), 'error');
                console.log(error);
            });
        }
    }, [setShowSpinner, showToast]);

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchOrderPaymentStatuses = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        const token = localStorage.getItem('token');

        // if(token && userId > 0){
        if(token !== null){
            if(showSpinner){
                setShowSpinner(true);
            }
            api.get('/get-order-payment-statuses', {
                headers:{
                  Authorization:`Bearer ${token}`,
                }
            }).then((res) => {
                setOrderPaymentStatuses(res.data);
                setTimeout(() => {
                    if(showSpinner){
                        setShowSpinner(false);
                    }
                }, spinnerTime);
            }).catch((error) => {
                setShowSpinner(false);
                showToast(t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur"), 'error');
                console.log(error);
            });
        }
    }, [setShowSpinner, showToast]);

    /**
     * 
     * @param startDate 
     * @param endDate 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchOrdersStatisticsDetails = useCallback((startDate: Date|null = null,
        endDate: Date|null = null, showSpinner: boolean = true, spinnerTime: number = 2000) => {

        let start_date = null;
        let end_date = null;

        if(startDate !== null){
            const startYear = startDate.getFullYear();
            const startMonth = startDate.getMonth() < 9 ? `0${startDate.getMonth() + 1}` : startDate.getMonth() + 1;
            const startDay = startDate.getDate() < 10 ? `0${startDate.getDate()}` : startDate.getDate();
            start_date = `${startYear}-${startMonth}-${startDay}`
        }
        if(endDate !== null){
            const endYear = endDate.getFullYear();
            const endMonth = endDate.getMonth() < 9 ? `0${endDate.getMonth() + 1}` : endDate.getMonth() + 1;
            const endDay = endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();
            end_date = `${endYear}-${endMonth}-${endDay}`
        }
        const token = localStorage.getItem('token');

        // if(token && userId > 0){
        if(token !== null){
            if(showSpinner){
                setShowSpinner(true);
            }
            api.get('/get-orders-statistics-details', {
                headers:{
                  Authorization:`Bearer ${token}`,
                },
                params: {
                    start_date: start_date,
                    end_date: end_date
                }
            }).then((res) => {
                setOrdersStatisticsDetails(res.data);
                setTimeout(() => {
                    if(showSpinner){
                        setShowSpinner(false);
                    }
                }, spinnerTime);
            }).catch((error) => {
                setShowSpinner(false);
                showToast(t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur"), 'error');
                console.log(error);
            });
        }
    }, [setShowSpinner, showToast]);

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchOrderStatuses = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        const token = localStorage.getItem('token');

        // if(token && userId > 0){
        if(token !== null){
            if(showSpinner){
                setShowSpinner(true);
            }
            api.get('/get-order-statuses', {
                headers:{
                  Authorization:`Bearer ${token}`,
                }
            }).then((res) => {
                setOrderStatuses(res.data);
                setTimeout(() => {
                    if(showSpinner){
                        setShowSpinner(false);
                    }
                }, spinnerTime);
            }).catch((error) => {
                setShowSpinner(false);
                showToast(t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur"), 'error');
                console.log(error);
            });
        }
    }, [setShowSpinner, showToast]);

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchSlices = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        const token = localStorage.getItem('token');

        if(token !== null){
            if(showSpinner){
                setShowSpinner(true);
            }
            api.get('/get-slices', {
                headers:{
                  Authorization:`Bearer ${token}`,
                }
            }).then((res) => {
                setSlices(res.data);
                setTimeout(() => {
                    if(showSpinner){
                        setShowSpinner(false);
                    }
                }, spinnerTime);
            }).catch((error) => {
                setShowSpinner(false);
                showToast(t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur"), 'error');
                console.log(error);
            });
        }
    }, [setShowSpinner, showToast]);

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchUserRoles = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        const token = localStorage.getItem('token');

        if(token !== null){
            if(showSpinner){
                setShowSpinner(true);
            }
            api.get('/get-roles', {
                headers:{
                  Authorization:`Bearer ${token}`,
                }
            }).then((res) => {
                setUserRoles(res.data);
                setTimeout(() => {
                    if(showSpinner){
                        setShowSpinner(false);
                    }
                }, spinnerTime);
            }).catch((error) => {
                setShowSpinner(false);
                showToast(t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur"), 'error');
                console.log(error);
            });
        }
    }, [setShowSpinner, showToast]);

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchUsers = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        const token = localStorage.getItem('token');

        if(token !== null){
            if(showSpinner){
                setShowSpinner(true);
            }
            api.get('/get-users-without-customers', {
                headers:{
                  Authorization:`Bearer ${token}`,
                }
            }).then((res) => {
                setUsers(res.data);
                setTimeout(() => {
                    if(showSpinner){
                        setShowSpinner(false);
                    }
                }, spinnerTime);
            }).catch((error) => {
                setShowSpinner(false);
                showToast(t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur"), 'error');
                console.log(error);
            });
        }
    }, [setShowSpinner, showToast]);

    /**
     * 
     */
    const fetchData = useCallback(() => {
        if(localStorage.getItem('token') !== null){
            fetchBoxTypes(false);
            fetchCategories(false);
            fetchSlices(false);
            fetchCustomers(false);
            fetchOrders(false);
            fetchOrderPaymentStatuses(false);
            fetchOrdersStatisticsDetails(null, null, false);
            fetchOrderStatuses(false);
            fetchUserRoles(false);
            fetchUsers(true, 2000);
        }
    }, [fetchBoxTypes, fetchCategories, fetchCustomers, fetchOrderPaymentStatuses, fetchOrderStatuses, fetchOrders, fetchOrdersStatisticsDetails, fetchSlices, fetchUserRoles, fetchUsers]);

    /**
     * 
     */
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    /**
     * Define the context value
     */
    const contextValue : DataContextType = {
        boxTypes,
        categories,
        customers,
        orders,
        orderPaymentStatuses,
        ordersStatisticsDetails,
        orderStatuses,
        slices,
        userRoles,
        users,
        statisticsCustomDateRange,
        statisticsEndDate,
        statisticsStartDate,
        fetchBoxTypes,
        fetchCategories,
        fetchCustomers,
        fetchData,
        fetchOrders,
        fetchOrderPaymentStatuses,
        fetchOrdersStatisticsDetails,
        fetchOrderStatuses,
        fetchSlices,
        fetchUserRoles,
        fetchUsers,
        setBoxTypes,
        setCategories,
        setCustomers,
        setOrders,
        setOrderPaymentStatuses,
        setOrdersStatisticsDetails,
        setOrderStatuses,
        setSlices,
        setUserRoles,
        setUsers,
        setStatisticsCustomDateRange,
        setStatisticsEndDate,
        setStatisticsStartDate
    };

    /**
     * 
     */
    return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};

export default DataProvider;
