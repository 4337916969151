import React from "react";

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";

import { ArrowLeft } from "react-bootstrap-icons";

import { useTranslation } from "react-i18next";

import useGoTo from "../../../components/hooks/useGoTo";
import NewUserFormView from "./components/NewUserFormView";

import './NewUserPageContent.css';

/**
 * 
 * @returns 
 */
const NewUserPageContent: React.FC = () => {
    /**
     * 
     */
    const { t } = useTranslation();

    /**
     * 
     */
    const goTo = useGoTo();

    /**
     * 
     */
    return (
        <div
            id="newUserPageContent"
            className="page-content position-relative"
        >
            <Container
                fluid={true}
                className="page-content-container p-5 p-sm-6 p-md-8 p-xl-10"
            >
                <Row className="gy-5">
                    <Col xs={12}>
                        <Card className='border-0'>
                            <CardBody className=''>
                                <div className='d-flex flex-row flex-wrap flex-stack'>
                                    <div className='d-flex align-items-center'>
                                        <Button
                                            tag='button'
                                            type='button'
                                            title={t('Retour')}
                                            color='light'
                                            className="d-flex flex-center gap-1 h-40px"
                                            onClick={() => goTo('/parametres/utilisateurs')}
                                        >
                                            <ArrowLeft />
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12}>
                        <NewUserFormView />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default NewUserPageContent;
